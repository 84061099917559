<template>
  <ServicesDescription
    :buttonText="buttonText"
    :isButtonIcon="isIcon"
    @connectService="getDocument()"
    :isFooterBtnLeft="isFooterBtnLeft"
  >
    <template #body>
      <!-- Шапка документа -->
      <b-row class="align-end">
        <b-col class="company-details__rowData dflex valign-end">
          <div>Директору {{ companyData.ShortName }}</div>
          <div>{{ companyData.ChiefFullNameDative }}</div>
          <span> от </span>
          <div class="company-details__input_wrap">
            <BaseTextInput
              v-model="userFormData.ResponsiblePersonPosition"
              class="company-details__input"
              placeholder="Должность"
            />
          </div>
          <div class="company-details__input_wrap">
            <BaseTextInput
              v-model="userFormData.ResponsiblePersonFullName"
              class="company-details__input"
              placeholder="ФИО ответственного лица"
            />
          </div>
          <span>
            {{ customerData.Name }}
          </span>
        </b-col>
      </b-row>
      <br />
      <br />
      <br />

      <!-- Заявка на изменение параметров -->
      <b-row>
        <b-col class="statement-type">
          <p>{{ statementTitle }}</p>
        </b-col>
      </b-row>
      <div class="dflex valign-center">
        <span class="statement-text">Просим по договору №</span>
        <div class="company-details__input_wrap">
          <BaseTextInput
            v-model="userFormData.ContractNumber"
            class="company-details__input"
            placeholder="Номер договора"
          />
        </div>
        <span class="statement-text">от</span>
        <div class="company-details__date_wrap">
          <BaseInputDate
            v-model="userFormData.ContractDate"
            class="company-details__date"
          />
        </div>
        <span class="statement-text">подключить с</span>
        <div class="company-details__date_wrap">
          <BaseInputDate
            v-model="userFormData.dateConnect"
            class="company-details__date"
          />
        </div>
        <span class="statement-text">следующие номера телефонов:</span>
        <BaseTextarea
          v-model="userFormData.numbersConnect"
          :rows="textareaRows"
          :placeholder="textareaPlaceholder"
        />
        <br />
        <span class="statement-text">и отключить с</span>
        <div class="company-details__date_wrap">
          <BaseInputDate
            v-model="userFormData.dateDisconnect"
            class="company-details__date"
          />
        </div>
        <span class="statement-text">следующие номера телефонов:</span>
        <br />
        <BaseTextarea
          v-model="userFormData.numbersDisconnect"
          :rows="textareaRows"
          :placeholder="textareaPlaceholder"
        />
      </div>
      <br />

      <!-- Подпись -->
      <b-row>
        <b-col class="dflex align-end valign-center">
          <BaseTextInput
            v-model="userFormData.ChiefPosition"
            class="company-details__input"
            placeholder="Должность"
          />
          <span> ____________ / </span>
          <BaseTextInput
            v-model="userFormData.ChiefFullName"
            class="company-details__input"
            placeholder="ФИО"
          />
        </b-col>
      </b-row>
    </template>
  </ServicesDescription>
</template>

<script>
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import BaseTextarea from '@/components/shared/inputs/BaseTextarea.vue';
import { ShowCurrentDate } from '@/additional_helpers/dates';
import { mapState } from 'vuex';
import BaseInputDate from '@/components/shared/inputs/BaseInputDate.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  // импортируется из ../StatementsCreation.vue
  inject: ['createDocument', 'isFooterBtnLeft', 'isIcon', 'buttonText'],

  data() {
    return {
      currentDate: ShowCurrentDate(),
      textareaRows: 3,
      statementTitle: 'Заявка на изменение параметров',
      emptyNumbers: 'Не указано',
      userFormData: {},
      textareaPlaceholder:
        'Введите номера телефонов через запятую. Не указывайте ничего, если этот раздел не актуален'
    };
  },

  components: {
    BaseTextInput,
    BaseInputDate,
    BaseTextarea,
    ServicesDescription
  },

  mounted() {
    this.userFormData = { ...this.currentData };
  },

  computed: {
    ...mapState({
      companyData: (state) => state.statements.module.companyData,
      customerData: (state) => state.statements.module.customerData
    }),

    currentData() {
      return {
        ContractNumber: this.customerData.ContractNumber,
        ContractDate: this.customerData.ContractDate.replace(/T00:00:00/, ''),
        dateConnect: this.currentDate,
        dateDisconnect: this.currentDate,
        numbersConnect: '',
        numbersDisconnect: '',
        ChiefPosition: '',
        ChiefFullName: this.customerData.ChiefFullName,
        ChiefFullNameGenitive: this.customerData.ChiefFullNameGenitive,
        ChiefPositionGenitive: this.customerData.ChiefPositionGenitive,
        ResponsiblePersonPosition: '',
        ResponsiblePersonFullName: ''
      };
    }
  },

  methods: {
    // Формирует объект для отправки на сервер
    createModelToSend() {
      return {
        CurrentCustomer: {
          ContractNumber: this.userFormData.ContractNumber,
          ContractDate: this.userFormData.ContractDate,
          ChiefPosition: this.userFormData.ChiefPosition,
          ChiefFullName: this.userFormData.ChiefFullName,
          ResponsiblePersonPosition: this.userFormData.ResponsiblePersonPosition,
          ResponsiblePersonFullName: this.userFormData.ResponsiblePersonFullName,
          DateOfStatement: ShowCurrentDate()
        },
        AdditionalInformation: {
          StartDate: `${this.userFormData.dateConnect}T00:00:00`,
          EndDate: `${this.userFormData.dateDisconnect}T00:00:00`
        },
        NumberConnections: {
          ToConnect: this.userFormData.numbersConnect,
          ToDisconnect: this.userFormData.numbersDisconnect
        }
      };
    },

    setEmptyValueToNumbers() {
      const { numbersConnect, numbersDisconnect } = this.userFormData;

      if (!numbersConnect && !numbersDisconnect) {
        this.userFormData.numbersDisconnect = this.emptyNumbers;
        this.userFormData.numbersConnect = this.emptyNumbers;
        return;
      }

      if (numbersConnect && !numbersDisconnect) {
        this.userFormData.numbersDisconnect = this.emptyNumbers;
      } else if (!numbersConnect && numbersDisconnect) {
        this.userFormData.numbersConnect = this.emptyNumbers;
      }
    },

    async getDocument() {
      await this.setEmptyValueToNumbers();
      this.createDocument(this.createModelToSend());
    }
  }
};
</script>

<style></style>
